
.modal-login-container {
    box-sizing: border-box;
    background-color: rgb(255, 255, 255);
    border-radius: 5rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 60rem;
    height: 40rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    z-index: 99;
}

.modal-login-container h2{
    padding: 2rem 0;
    text-align: center;
}
.modal-login-container label{
    display: inline-block;
    width: 100%;
    padding: 1rem 5rem;
}
.modal-login-container p {
    text-align: left;
    font-weight: 700;
}
.modal-login-container input{
    display: inline-block;
    width: 100%;
    padding: 0.6rem 1rem;
}

.modal-login-container .btn-send{
    display: block;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    padding:  0.3rem 1rem;
    border-radius: 1rem;
    margin-top: 1rem;
    box-shadow: 0 3px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    transition: 0.3s;
    background: #ffffff;
    text-transform: uppercase;
    color: #000000;
    padding: 0.3rem 2rem;
}

.modal-login-container .btn-send:hover{
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}



.modal-login-container .btn-close{
    text-transform: uppercase;
    border: none;
    box-shadow: 0 3px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    padding: 0.3rem 2rem;
    border-radius: 2rem;
    position: absolute;
    color: white;
    background: #e00404;
    top: 2rem;
    right: 3rem;
    font-size: 1.5rem;
    cursor: pointer;
    flex-direction: column;
    transition: 0.3s;
}
.modal-login-container .btn-close:hover{
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}

