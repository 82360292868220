@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap');
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html{
  font-size: 62.5%;
}

body{
  font-family: 'Montserrat', sans-serif;
  font-size: 1.6rem;
}
