.table-container{
    position: relative;
    width: 90vw;
    /*min-height: 90vh;*/
    background: rgba(0, 0, 0, 0.5);
    left: 50%;
    transform: translateX(-50%);
    padding: 7rem 0;
    z-index: 1000;
}
.table-container h2{
    color:white;
    text-align: center;
    margin-bottom: 1rem;
    font-size: 4rem;
}
.fault-table {
    position: relative;
    min-width: 90%;
    left: 50%;
    transform: translateX(-50%);
    background: white;
    border: 1px solid black;
    border-collapse: collapse;

}
.fault-table input{
    font-size: 1.6rem;
    border: 1px solid red;

}
.fault-table td,th {
    border: 1px solid black;
    padding:  0.2rem 1rem;
    text-align: left;
    max-height: 100%;
}
.fault-table td{
    width: 70%;
}
.fault-table button{
    padding: 0.5rem 2rem;
}
.fault-table .status{
    font-weight: bold;
    color: blue;
}

.sort-buttons{
    padding-top: 16rem;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-content: center;
}
